<template>
  <v-container fluid>
    <div class="mt-2">
      <p class="text-h5">Seleccionar reporte</p>
      <v-row>
        <v-col cols="3">
          <v-select
            v-model="selectEvento"
            :items="itemsEventosPorGestion"
            label="Eventos por gestión"
            prepend-icon="mdi-chart-bar"
            dense
          ></v-select>
        </v-col>
      </v-row>
    </div>

    <v-card elevation="3">
      <v-row justify="space-between" class="ml-4 mr-2">
        <v-col cols="3">
          <p class="text-h6 font-weight-regular">
            Reporte de eventos por gestión
          </p>
          <p class="text-caption text--secondary font-weight-bold mt-n3 pb-4">
            <v-icon class="" color="black">mdi-calendar-range</v-icon>
            {{ fechaSelect }}
          </p>
        </v-col>
        <v-col cols="1">
          <div class="text-end mt-1">
            <v-btn
              v-if="open"
              @click="open = false"
              fab
              outlined
              small
              color="#031D6A"
            >
              <v-icon color="black">mdi-chevron-down</v-icon>
            </v-btn>

            <v-btn
              v-if="!open"
              @click="open = true"
              fab
              outlined
              small
              color="#031D6A"
            >
              <v-icon color="black">mdi-chevron-up</v-icon>
            </v-btn>
          </div>
          <div class="text-end mt-2">
            <v-btn color="green" outlined fab small title="Descargar reporte">
              <v-icon>mdi-microsoft-excel </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <!-- PARA VER LOS DEMÁS PARÁMETROS EXPANDIBLES -->
      <div class="mx-8" v-if="!open">
        <p class="text-body-2 font-weight-bold">
          Cambiar parámetros de búsqueda
        </p>

        <v-row>
          <v-col cols="2">
            <v-menu
              ref="fechaDialog"
              v-model="modalFechas"
              :close-on-content-click="false"
              :nudge-right="0"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedFecha"
                  label="Días"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                :selected-items-text="'$vuetify.datePicker.itemsSelected'"
                locale="es"
                scrollable
                v-model="dialogVarSelectedFecha"
                range
                :max="disabledFecha"
                color="primary"
                header-color="primary"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFechas = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.fechaDialog.save(dialogVarSelectedFecha)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-text-field
              type="search"
              v-model="searchCampania"
              label="Campaña"
              prepend-icon="mdi-database-search"
              autocomplete="false"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="selectSector"
              :items="itemsSector"
              label="Sector"
              prepend-icon="mdi-database-search"
              dense
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="selectRegion"
              :items="itemsRegion"
              label="Región"
              prepend-icon="mdi-database-search"
              dense
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col cols="2">
            <v-select
              v-model="selectDivision"
              :items="itemsDivision"
              label="División"
              prepend-icon="mdi-database-search"
              dense
            ></v-select>
          </v-col>

          <v-col cols="2">
            <div class="text-center">
              <v-btn
                color="#031D6A"
                class="white--text"
                small
                @click="fnAplicarParametros()"
              >
                APLICAR PARÁMETROS
                <v-icon right dark> mdi-magnify </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- tabla -->
    <v-data-table
      v-if="open"
      :headers="headers"
      :items="datosTabla"
      item-key="name"
      class="elevation-2 mt-8"
    >
      <!-- para colocar la palabra "evento" luego de la cantidad de eventos en la tabla -->
      <template v-slot:[`item.cantidadEventos`]="{ item }">
        <span> {{ item.cantidadEventos }} eventos</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from 'moment';
require('moment/locale/es.js');
export default {
  name: 'reporteEventoPorGestion',
  data() {
    return {
      open: true, //para desplegar los demás parámetros de búsqueda
      selectEvento: null, // para seleccionar un item para filtrar el reporte

      fechaSelect: 'Del 01 octubre 2023 al 31 octubre 2023',

      //items seleccionables
      itemsEventosPorGestion: [
        'Eventos por gestión',
        'Contratos completos e incompletos',
        'Gestiones atrasadas',
        'Gestiones con complementos pendientes y compromiso de pago',
        'Gestiones por sector, color y autorización',
        'Información ingresada por supervisora',
        'Evaluaciones eliminadas',
        'Gestiones por sector',
        'Usuarios por bandeja',
      ],

      //para los demás parámetros de búsqueda que se muestran al expandir el panel
      searchCampania: null,
      selectSector: null,
      selectRegion: null,
      selectDivision: null,

      itemsSector: ['sector 1', 'sector 2', 'sector 3'],
      itemsRegion: ['región 1', 'región 2', 'región 3'],
      itemsDivision: ['división 1', 'división 2', 'división 3'],

      //variables para la fecha
      modalFechas: false,
      dialogVarSelectedFecha: null,
      selectedFecha: [
        moment().startOf('month').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
      ], //en esta variable es donde se almacenará la fecha o el rango de fechas
      disabledFecha: moment().format('YYYY-MM-DD'),

      //encabezado de la tabla
      headers: [
        {
          text: 'Número evaluación',
          align: 'start',
          value: 'evaluacion',
          sortable: false,
        },
        {
          text: 'Nombre persona',
          align: 'start',
          value: 'persona',
          sortable: false,
          width: 170,
        },
        {
          text: 'Documento identificación',
          align: 'start',
          value: 'numeroId',
          sortable: false,
        },
        {
          text: 'Dirección',
          align: 'start',
          value: 'direccion',
          sortable: false,
        },
        {
          text: 'Teléfono',
          align: 'start',
          value: 'telefono',
          sortable: false,
        },
        {
          text: 'Fecha evaluación',
          align: 'start',
          value: 'fechaEvaluacion',
          sortable: false,
        },
        { text: 'Campaña', align: 'start', value: 'campania', sortable: false },
        {
          text: 'Fecha campaña',
          align: 'start',
          value: 'fechaCampania',
          sortable: false,
        },
        { text: 'Sector', align: 'start', value: 'sector', sortable: false },
        { text: 'Región', align: 'start', value: 'region', sortable: false },
        {
          text: 'División',
          align: 'start',
          value: 'division',
          sortable: false,
        },
        {
          text: 'Cantidad de eventos',
          align: 'start',
          value: 'cantidadEventos',
          sortable: false,
        },
      ],
      //datos para mostrar en la tabla (se tiene dato de prueba)
      datosTabla: [
        {
          evaluacion: 33256,
          persona: 'Luisa Lópe perez',
          numeroId: '231620690304',
          direccion: '7a. Av. 3-02 zona 1',
          telefono: '55555555',
          fechaEvaluacion: '15 oct. 10:47 am',
          campania: '2311',
          fechaCampania: '15/10/2023',
          sector: '95',
          region: 'Sur Occidente',
          division: 'División 1',
          cantidadEventos: 7,
        },
      ],
    };
  },
  methods: {
    //función que reacciona cuando se da clic en el botón de APLICAR PARÁMETROS dentro del panel expandible
    fnAplicarParametros() {
      console.log('aplicando parámetros de búsqueda:');
      this.page = 1;

      const parametros = {
        dias: this.selectedFecha,
        campania: this.searchCampania,
        sector: this.selectSector,
        region: this.selectRegion,
        division: this.selectDivision,
      };
      console.log(parametros);
      this.open = true; //se contrae el *panel expandible
    },
  },

  watch: {
    /** parametrización por fechas con el calendario*/
    dialogVarSelectedFecha(newValue) {
      this.selectedFecha = [
        moment(newValue[0]).format('DD-MM-YYYY'),
        moment(newValue[1]).format('DD-MM-YYYY'),
      ];
    },
  },
};
</script>

<style></style>
